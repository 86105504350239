.folio-page {
  padding-top: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.folio-wrapper {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: auto;
  max-width: 900px;
  width: 100%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.folio-wrapper-heading {
  display: flex;
  border-radius: 17px 17px 0 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background: #00378a;
  color: #fff;
}

.folio-heading {
  color: #fff;
  font-size: 15px;
  text-align: center;
  margin: 1rem;
}

.container-folio-form {
  width: 100%;
}

.folio-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 30px;
  margin-top: 1rem;
  padding: 0rem;
  box-sizing: border-box;
  color: #000;
}

.folio-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 0rem;
  width: 100%;
}

.folio-input {
  padding: 4px;
  font-size: 15px;
  border: 1px solid #4d4d4d;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  resize: vertical;
}

.folio-input-check {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 8px;
  margin-bottom: 0.5em;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.distributor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

h1.initial {
  margin: 5px 0 10px 0;
}

.folio-label-check {
  margin: 0.5em;

}

.msg-error {
  text-align: left;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 5px 0px;
  font-weight: 500;
  color: #fff;
  background-color: #d9534f;
}

.msg-error-final {
  text-align: left;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 5px 0px;
  font-weight: 500;
  color: #fff;
  background-color: #d9534f;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@media (max-height: 640px) {
  .folio-wrapper {
    margin-top: auto;
  }

  .folio-heading {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .folio-column {
    width: 100% !important;
    padding: 0px !important;
  }
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  appearance: none;
  display: none;
}

.folio-row::after {
  content: "";
  clear: both;
  display: table;
}

.folio-column:nth-of-type(2n+1) {
  float: left;
  width: 50%;
  padding: 0px 10px 0px 0px;
}

.folio-column:nth-of-type(2n) {
  float: left;
  width: 50%;
  padding: 0px 0px 0px 10px;
}

.title-section-folios {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: bold;
}

.folio-column-full {
  float: left;
  width: 100%;
}

.folio-column-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-center {
  margin: 0px !important;
  width: 200px;
  height: 40px;
  font-size: 20px !important;
}

.text-area {
  resize: none;
  height: 150px;
}

.input-date {
  resize: none;
}

.msg-info {
  text-align: left;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 5px 0px;
  font-weight: 500;
  color: #fff;
  background-color: #31a5d3;
}