.modal {
    display: none;
}

.modal.is-open {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #00378a;
    padding: 20px;
    border-radius: 17px 17px 0 0;
    height: 100px;
}

.header-modal {
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
}

.cerrar {
    color: #fff;
    font-size: 1.5rem;
}

.cerrar:hover {
    color: #000;
    font-size: 1.5rem;
}

.modal-content {
    background-color: #fff;
    margin: 5% auto;
    border-radius: 20px;
}

.modal-body {
    padding: 20px;
    margin-top: 10px;
}