.row {
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: block;
}

.row-content-wrap {
  color: inherit;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #00378a;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  box-sizing: border-box;
  display: block;
}

.row-content {
  color: inherit;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: block;
}

.col-group-inicio {
  color: inherit;
  flex-wrap: wrap;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
}

.col-inicio {
  color: inherit;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-content-inicio {
  color: inherit;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.equal-height {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  width: 100%;
}

.module-inicio {
  color: inherit;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-inicio {
  color: inherit;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-content-inicio {
  color: inherit;
  text-align: left;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-title-inicio {
  color: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-title {
  font-weight: 700;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 36px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.title-text-inicio {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  display: inline-block;
  color: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.heading-description {
  color: inherit;
  line-height: 1.6;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.description-inicio {
  margin-bottom: 0;
  color: inherit;
  line-height: 1.6;
  margin: 0 0 30px;
  padding: 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: left;
  font-size: 22px;
}

.separador-inicio {
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
}

.linea-sep {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  width: 30px;
  float: left;
  color: inherit;
  max-width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.logos-wrapper::before,
.logos-container::before,
.module-content-inicio-logos::before,
.module-content-inicio::before,
.module-inicio::before {
  display: table;
  content: " ";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.logos-wrapper::after,
.logos-container::after,
.module-content-inicio-logos::after,
.module-inicio::after {
  clear: both;
  display: table;
  content: " ";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module-content-inicio-logos {
  color: inherit;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.logos-container {
  color: inherit;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.logos-wrapper {
  color: inherit;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.logo:nth-of-type(2n) {
  margin-right: 0;
}

.logo:nth-of-type(2n+1) {
  clear: left;
}

.logo {
  color: inherit;
  border-style: none;
  border-width: 0;
  background-clip: border-box;
  border-color: #eaeaea;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: calc((100% - 1px) / 2);
  margin-bottom: 0px;
  float: left;
  transition: background-color 0.3s ease-in-out;
  backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.link-logo {
  display: block;
  text-decoration: none;
  box-shadow: none;
  border: none;
  flex: 1 1 auto;
  color: #ffffff;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  cursor: pointer;
}

.logo-inner {
  flex: 1 1 auto;
  color: inherit;
  display: inline !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.logo-inner-wrap {
  text-align: center;
  color: inherit;
  display: block !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.image-wrapper {
  color: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.logo-image {
  color: inherit;
  -webkit-filter: inherit;
  filter: inherit;
  border-style: none;
  border-width: 1px;
  border-radius: 0px;
  margin: 0 auto;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
}


@media (max-width: 768px) {
  .row-content-wrap {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
  
  .col-group-inicio{
    display: block;
  }
  
  .col-inicio{
    max-width: 400px;
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }

  .col-content-inicio{
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}


@media only screen and (max-width: 768px){
  .heading-content-inicio{
    text-align: center;
  }
  
  .linea-sep{
    margin: 0 auto;
    float: none;
  }

  .logo{
    width: calc((100% - 1px) / 2);
  }

  .logo:nth-of-type(2n) {
    margin-right: 0;
    margin-bottom: 0px;
  }
  
  .logo:nth-of-type(2n+1) {
    clear: left;
  }
}

@media only screen and (max-width: 992px){
  .heading-content-inicio{
    text-align: center;
  }

  .linea-sep{
    margin: 0 auto;
    float: none;
  }

  .logo{
    width: calc((100% - 1px) / 2);
  }

  .logo:nth-of-type(2n) {
    margin-right: 0;
    margin-bottom: 0px;
  }
  
  .logo:nth-of-type(2n+1) {
    clear: none;
  }
}