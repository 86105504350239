.row-jumbotron {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-content-wrap-jumbotron {
  padding-top: 100px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  z-index: 1;
}

.row-content-jumbotron {
  position: relative;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-group-jumbotron {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-jumbotron {
  margin-top: 10px;
  width: 100%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-content-jumbotron {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-jumbotron {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-jumbotron {
  margin-top: 50px;
  margin-bottom: 50px;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-content-jumbotron {
  text-align: center;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-jumbotron {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-title-jumbotron {
  font-size: 70px;
  line-height: 1.4;
  margin-bottom: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 700;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.title-text-jumbotron {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  display: inline-block;
  color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-jumbotron::before,
.row-content-jumbotron::before,
.col-group-jumbotron::before,
.col-jumbotron::before,
.module-jumbotron::before,
.module-content-jumbotron::before {
  display: table;
  content: " ";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-jumbotron::after,
.row-content-jumbotron::after,
.col-group-jumbotron::after,
.col-jumbotron::after,
.module-jumbotron::after,
.module-content-jumbotron::after {
  clear: both;
  display: table;
  content: " ";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-content-wrap-jumbotron::before {
  background-color: rgba(0, 55, 138, 0.76);
  border-radius: inherit;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .row-content-wrap-jumbotron{
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    background-attachment: scroll !important;
  }

  .col-jumbotron{
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }

  .col-content-jumbotron{
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .module-content-jumbotron{
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .heading-title-jumbotron{
    font-size: 40px;
  }
}


@media (max-width: 992px) {
  .row-content-wrap-jumbotron{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}


@media only screen and (max-width: 768px){
  .heading-content-jumbotron{
    text-align: center;
    margin-top: -70px;
  }
}

@media only screen and (max-width: 992px){
  .heading-content-jumbotron{
    text-align: center;
    margin-top: -70px;
  }
}