.row-nosotros {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-nosotros::before,
.row-content-nosotros::before,
.col-group-nosotros::before,
.col-nosotros::before,
.module-nosotros::before,
.module-content-nosotros::before,
.row-content-wrap-nosotros::before,
.row-content-nosotros-info::before,
.module-nosotros-info::before,
.module-content-nosotros-info::before,
.col-group-nosotros-info::before,
.module-content-nosotros-info2::before {
  display: table;
  content: " ";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-nosotros::after,
.row-content-nosotros::after,
.col-group-nosotros::after,
.col-nosotros::after,
.module-nosotros::after,
.module-content-nosotros::after,
.row-content-wrap-nosotros::after,
.row-content-nosotros-info::after,
.module-nosotros-info::after,
.module-content-nosotros-info::after,
.col-group-nosotros-info::after,
.module-content-nosotros-info2::after {
  clear: both;
  display: table;
  content: " ";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-content-wrap-nosotros {
  padding-top: 100px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  height: 420px;
  z-index: 1;
}

.row-content-wrap-nosotros::before {
  background-color: rgba(0, 55, 138, 0.7);
  border-radius: inherit;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-content-nosotros {
  position: relative;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-group-nosotros {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-nosotros {
  margin-top: 60px;
  width: 100%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-content-nosotros {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-nosotros {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-nosotros {
  margin-top: 50px;
  margin-bottom: 50px;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-content-nosotros {
  text-align: center;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-nosotros {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-title-nosotros {
  font-size: 70px;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 700;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.title-text-nosotros {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  display: inline-block;
  color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-content-wrap-nosotros-info {
  padding-top: 50px;
  padding-bottom: 50px;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}


.row-content-nosotros-info {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-group-nosotros-info {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-nosotros-info {
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


.col-content-nosotros-info {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
}

.module-nosotros-info {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-nosotros-info {
  margin-bottom: 0px;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-content-nosotros-info {
  text-align: left;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-nosotros-info {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-title-nosotros-info {
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 25px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 20px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.title-text-nosotros-info {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  display: inline-block;
  color: #3c4154;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.separador-nosotros {
  color: #000000;
  margin-top: 0px;
  margin-bottom: 30px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.linea-nosotros {
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: #3c4154;
  width: 60px;
  float: left;
  max-width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-group-nosotros-info {
  min-height: 1px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-nosotros-info {
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-content-nosotros-info2 {
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module-nosotros-info2 {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-nosotros-info2 {
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.rich-text-nosotros {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.text-nosotros {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 0 30px;
  padding: 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 22px;
  line-height: 1.75;
}

.imageinfo {
  max-width: 100%;
  margin: 0 0 20px 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: right;
  text-align: right;
  height: auto;
  border-style: none;
  width: 264px;
  aspect-ratio: auto 264 / 348;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.row-content-wrap-color {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f8f8f8;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-nosotros-work {
  margin-top: 70px;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-content-nosotros-work {
  text-align: center;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-nosotors-work {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-title-work {
  font-size: 40px;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 700;
  margin: 0 0 20px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.tittle-text-nosotros-work {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  display: inline-block;
  color: #3c4154;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module-content-nosotros-work-button {
  margin-top: 10px;
  margin-bottom: 60px;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.button-wrap-nosotros {
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.button-work {
  border-style: none;
  border-width: 0;
  background-clip: border-box;
  border-color: #333333;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  line-height: 1.6;
  transition-duration: 500ms;
  text-decoration: none;
  width: 230px;
  background: #00378a;
  background-clip: border-box;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  color: #ffffff;
  -webkit-transition: all .3s ease 0s;
  -moz-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  -ms-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-shadow: none;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  -o-transition: all .3s linear;
  -ms-transition: all .3s linear;
  transition: all .3s linear;
  display: inline-block;
  text-align: center;
  max-width: 100%;
  box-sizing: border-box;
}

.button-work-text {
  color: #ffffff;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .row-content-wrap-nosotros-info {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    background-attachment: scroll !important;
  }

  .col-group-nosotros-info {
    display: block;
  }

  .col-nosotros-info {
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }

  .col-content-nosotros-info {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width:768px) {
  .heading-content-nosotros-info {
    text-align: center;
  }

  .linea-nosotros {
    margin: 0 auto;
    float: none;
  }
}

@media only screen and (max-width: 992px) {
  .heading-content-nosotros-info {
    text-align: center;
  }

  .linea-nosotros {
    margin: 0 auto;
    float: none;
  }
}