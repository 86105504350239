@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.site-header {
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid #eee;
}

.navbar {
  margin-top: 8px;
  touch-action: pan-y;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.nav-links {
  text-decoration: none;
  color: #000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 30px 18.7px;
}

.nav-links:hover,
.nav-links .active {
  color: #00378a;
}

.enlace img {
  margin-top: 5px;
  margin-bottom: 21px;
  margin-right: 50px;
  max-width: 200px;
}

.nav-menu {
  margin-top: -7px;
  list-style: none;
  display: flex;
  gap: 10px;
}

.highlight {
  border: 2px solid;
  padding: 10px;
}

ol,
ul {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.icon-navbar {
  color: #000;
}

.menu-icon {
  display: none;
}


@media screen and (max-width:1042px) {
  .navbar {
    height: 100%;
  }

  .nav-menu {
    display: none;
  }

  .nav-item {
    height: 70px;
    display: block;
    margin-top: 30px;
    line-height: 10px;
  }

  .nav-menu.active {
    margin: auto;
    display: block;
    flex-direction: column;
    position: absolute;
    padding-right: 5px;
    width: auto;
    background: #fff;
    top: 104px;
    text-align: left;
    transition: all .5s;
    border-left: 1px solid #eee;
    right: 0%;
    z-index: 9;
  }

  .nav-links {
    font-size: 20px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: 15px;
  }

  .icon-navbar {
    color: #000 !important;
    font-size: 2rem !important;
  }

  .icon-navbar {
    color: #000;
  }
}