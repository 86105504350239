.cleanB {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1rem;
}


.cleanB:disabled {
  display: none;
}