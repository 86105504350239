@import url('https://fonts.googleapis.com/css?family=Chivo:300,300i,400,400i,700,700i,900,900i|Saira+Extra+Condensed:100,200,300,400,500,600,700,800|Saira:100,200,300,400,500,600,700,800');


.span-seguimiento {
  font-weight: normal;
}

.table-seguimiento {
  margin-top: 30px;
}

.header-table-seguimiento {
  margin-bottom: 0px;
}

.column-right {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}


@media screen and (max-width: 600px) {
  .column-right {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
  }
}

/* Import */

@import url('https://fonts.googleapis.com/css?family=Chivo:300,300i,400,400i,700,700i,900,900i|Saira+Extra+Condensed:100,200,300,400,500,600,700,800|Saira:100,200,300,400,500,600,700,800');

/* Base */

#timeline-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Timeline */
.timeline {
  border-left: 4px solid #00378a;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  letter-spacing: 0.5px;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px 50px 50px 0;
  list-style: none;
  text-align: left;
  font-weight: 100;
  box-sizing: border-box;
  margin: 0 0 0 340px;

  h2,
  h3 {
    margin-left: 10px;
    font-family: 'Saira', sans-serif;
    letter-spacing: 1.5px;
    font-weight: 400;
    font-size: 1.2em;
  }

  .event {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: calc((((130px * 0.6) + 1px + 4px + 11px + (4px * 2)) * 1.5) * -1);
      color: #000;
      content: attr(data-date);
      text-align: right;
      font-weight: 700;
      font-size: 0.9em;
      min-width: 120px;
      font-family: 'Saira', sans-serif;
    }

    &:after {
      box-shadow: 0 0 0 4px #00378a;
      left: calc((1px + 15px + (11px * 0.35)) * -1);
      background: #fff;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      color: #000;
      font-weight: 700;
      content: "";
      top: -5px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }

    .history-icon {
      position: relative;
      z-index: 1;
      font-size: 20px;
      right: 12px;
      top: 1.5px;
    }
  }
}

@media screen and (max-width: 924px) {
  .container-line {
    padding: 0 0 0 260px;
  }

  .timeline {
    margin: 0 0 0 0;
  }

  .timeline h2,
  .timeline h3 {
    margin-left: 10px;
    font-family: 'Saira', sans-serif;
    letter-spacing: 1.5px;
    font-weight: 400;
    font-size: .8em;
  }

  .timeline .event:before {
    font-size: .7em;
  }
}