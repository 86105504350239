.jumbotron {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  position: relative;
  color: #fff;
  height: 609.43px;
  z-index: 1;
}

.container {
  position: relative;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.col-group {
  box-sizing: border-box;
}

.col {
  margin-top: 100px;
  width: 66.7%;
  float: left;
  min-height: 1px;
  box-sizing: border-box;
}

.col-content {
  box-sizing: border-box;
}

.module {
  width: 100%;
  box-sizing: border-box;
}

.module-content {
  margin-top: 90px;
  margin-bottom: 90px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  margin: 20px;
  box-sizing: border-box;
}

.module-content-content {
  text-align: left;
  box-sizing: border-box;
}

.info,
.info-content,
.description-container {
  box-sizing: border-box;
}

.module-content::before .module::before,
.col::before,
.col-group::before,
.container::before {
  display: table;
  content: " ";
  box-sizing: border-box;
}


.module-content::after .module::after,
.col::after,
.col-group::after,
.container::after {
  clear: both;
  display: table;
  content: " ";
  box-sizing: border-box;
}

.jumbotron::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: -1;
}

.jumbotron h1 {
  margin-top: 120px;
  margin-bottom: 12px;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #fff;
  margin: 0;
  padding: 0;
  clear: both;
  line-height: 1.2;
}

.jumbotron p {
  margin-top: 50px;
  color: #fff !important;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 2rem;
}

.jumbotron hr {
  border-color: #fff;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.jumbotron p:last-child {
  margin-bottom: 0;
  position: absolute;
  bottom: 20px;
}

.jumbotron h1,
.jumbotron p,
.jumbotron hr,
.jumbotron p:last-child {
  position: relative;
  z-index: 1;
}

.button-marcas {
  color: #ffffff;
  background: #00378a;
  border: 1px solid #00378a;
  font-size: 18px;
  line-height: 20px;
  padding: 12px 24px;
  text-decoration: none;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  text-decoration: none;
  text-shadow: none;
  box-shadow: none;
  position: relative;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  outline-width: 0;
}

@media (max-width:768px) {
  .jumbotron{
    padding-top: 40px;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    background-attachment: scroll !important;
  }

  .col{
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }

  .col-content{
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
  
  .module-content{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}