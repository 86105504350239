.dropdown-menu {
  background: #ffffff;
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  z-index: 9;
}

.dropdown-menu li {
  background: #ffffff;
  cursor: pointer;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  padding: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.dropdown-link:hover {
  color: #00378a;
}

@media screen and (max-width: 960px) {
  .dropdown-menu {
    position: static;
    width: 100%;
    margin-top: 20px;
    margin-left: 5px;
  }
}