.negritas {
  color: #007a4b !important;
}

.indicaciones {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title-clientes{
  margin-top: 0px;
  margin-bottom: 0px;
}

.table-info {
  border: 1px solid #dee2e6;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  display: table;
  text-indent: initial;
  border-spacing: 2px;
}

.table-body {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.renglon-modal {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.columnas-modal {
  padding: 0.75rem;
  vertical-align: top;
  text-align: -webkit-match-parent;
  border: 1px solid #dee2e6;
}

.valor-modal {
  padding: 0.75rem;
  vertical-align: top;
  text-align: -webkit-match-parent;
  border: 1px solid #dee2e6;
}