.site-inner {
  clear: both;
  margin: 73px auto 0;
  max-width: 1280px;
  word-wrap: break-word;
  box-sizing: inherit;
  display: block;
  font-size: 2.2rem;
}

.content {
  border-right: none;
  float: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 840px;
  width: 100%;
  padding: 80px 60px;
  display: block;
  box-sizing: inherit;
}

.aviso {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 0;
  display: block;
  box-sizing: inherit;
}

.header {
  text-align: center;
  display: block;
  box-sizing: inherit;
}

.header::after {
  border-bottom: 1px solid #000;
  content: "";
  display: block;
  margin: 0 auto 40px;
  padding-bottom: 10px;
  width: 2.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin: 0 0 20px;
  text-transform: none;
  color: #000;
  margin: 0 0 20px;
}

.fl-row {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.fl-wrap {
  padding: 20;
  margin: 0;
  position: relative;
  box-sizing: border-box;
}

.separador {
  z-index: 9;
  top: 0;
  bottom: auto;
  position: absolute;
  width: 100%;
  left: 0;
}

.fl-row-content {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: block;
}

.fl-col-group {
  box-sizing: border-box;
  display: block;
}

.fl-col {
  width: 100%;
  float: left;
  min-height: 1px;
  box-sizing: border-box;
}

.fl-col-content {
  box-sizing: border-box;
}

.fl-module {
  box-sizing: border-box;
}

.fl-module-content {
  margin: 20px;
  box-sizing: border-box;
}

.puntoPriv {
  font-size: 36px;
}

.titleAviso {
  font-size: 48px;
}

.descripcion {
  margin: 0 0 30px;
  padding: 0;
  font-size: 22px;
  line-height: 1.75;
  font-weight: 400;
  box-sizing: border-box;
}


.ulAviso {
  font-size: 22px;
  line-height: 1.75;
  margin-bottom: 30px;
  margin-left: 40px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.ulAviso>li {
  list-style-type: decimal;
  box-sizing: border-box;
}

.h2Aviso{
  font-size: 42px;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.tituloPequeño{
  font-size: 27px;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}