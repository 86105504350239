@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

::-webkit-scrollbar {
    width: 8px;
    margin-right: 15px;
}

/* Color de fondo de la barra de desplazamiento */
::-webkit-scrollbar-track {
    background-color: #ffffff;
    margin-right: 15px;
}

/* Color del "pulgar" de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 5px;
}

/* Cambia el color del "pulgar" de la barra de desplazamiento cuando se le da hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #000000;
}

* {
    margin: 0;
    padding: 0;

    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}