.contenedor-padre {
    padding: 10px;
    display: flex;
    justify-content: center;
}

.card {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 80%;
    max-width: 80%;
    margin: 0 auto;
}

.card .title-container {
    border-radius: 17px 17px 0 0;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    background: #00378a;
}

.title-container .title {
    color: #fff;
    margin: 10px;
    font-size: 15px;
}

.table-container {
    overflow-x: auto;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.tabla {
    width: 100%;
    border-collapse: collapse;
}

.tabla th,
.tabla td {
    padding: 5px;
    border: 1px solid #9b9b9b;
}

.tabla th {
    background-color: #f2f2f2;
    color: #333;
}

.tabla td {
    font-size: 15px;
    border: 1px solid #9b9b9b;
}

.fixed {
    text-align: right;
    font-weight: 700;
}

.container-facturar {
    display: flex;
    float: right;
}

.facturar {
    padding: 0.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    margin: 0px 20px 20px 20px;
    background-color: #00378a;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.facturar:hover {
    background-color: #001535;
}

.tabla .cost {
    font-size: 15px;
    text-align: right;
    border: 1px solid #9b9b9b;
}