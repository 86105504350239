.row-marcas-marcas {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-content-wrap-marcas-blog {
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-content-marcas-blog {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-group-marcas-blog {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-marcas-blog {
  width: 100%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-content-marcas-blog {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-marcas-blog {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-marcas-blog {
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.blogs {
  margin: 0 -7.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.blog-container-marcas {
  padding-left: 7.5px;
  padding-right: 7.5px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  vertical-align: top;
  float: left;
  outline: none;
  border-top: 0px solid #e5e5e5;
  position: relative;
  z-index: 1;
  width: 50%;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}

.blog-posts-marcas {
  box-shadow: 0 4px 1px rgba(197, 197, 197, 0.2);
  background: #f6f6f6;
  transition: all 0.3s linear;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.blog-post-marcas {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.post-thumbnail-marcas {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.link-marcas {
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
  color: #00378a;
}

.imagen-marcas {
  display: inline-block;
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
  border-style: none;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.post-content-marcas {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  width: 100%;
  opacity: 1;
  position: relative;
  z-index: 10;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 22px;
}

.read-more-text-marcas {
  color: #00378a;
  padding-top: 1.5em;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ver-marca {
  color: #00378a;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out b;
  -moz-transition: all 0.2s ease-in-out b;
  -ms-transition: all 0.2s ease-in-out b;
  -o-transition: all 0.2s ease-in-out b;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  cursor: pointer;
}

.ver-mas-marcas {
  color: #00378a;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-content-wrap-marcas::before {
  background-color: rgba(0, 55, 138, 0.8);
  border-radius: inherit;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.blog-posts-marcas::before,
.module-content-marcas-blog::before,
.module-marcas-blog::before,
.col-marcas-blog::before,
.col-group-marcas-blog::before,
.row-content-marcas-blog::before,
.row-marcas-marcas::before{
  display: table;
  content: " ";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.blog-posts-marcas::after,
.module-content-marcas-blog::after,
.module-marcas-blog::after,
.col-marcas-blog::after,
.col-group-marcas-blog::after,
.row-content-marcas-blog::after,
.row-marcas-marcas::after {
  clear: both;
  display: table;
  content: " ";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}