.buscador-page {
    padding-top: 20px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buscador-wrapper {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    max-width: 400px;
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.buscador-wrapper-heading {
    display: flex;
    border-radius: 17px 17px 0 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    background: #00378a;
    color: #fff;
}

.buscador-heading {
    font-size: 15px;
    text-align: center;
    margin: 1rem;
}

.container-form {
    width: 100%;
}

.buscador-form {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 30px;
    margin-top: 1rem;
    padding: 0rem;
    box-sizing: border-box;
    color: #000;
}

.buscador-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem;
    width: 100%;
}

.buscador-input {
    padding: 4px;
    font-size: 15px;
    border: 1px solid #4d4d4d;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
}

.msg-error {
    text-align: left;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    margin: 5px 0px 5px 0px;
    font-weight: 500;
    color: #fff;
    background-color: #d9534f;
}

@media (max-height: 640px) {
    .buscador-wrapper {
        margin-top: auto;
    }

    .buscador-heading {
        font-size: 1.5rem;
    }
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
    appearance: none;
    display: none;
}