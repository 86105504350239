.row-contacto {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-color {
  color: #333333;
}

.row-content-wrap-contacto-color {
  color: inherit;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  background-color: #ffcc00;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-content-contacto-color {
  color: inherit;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-group-contacto-color {
  color: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-contacto-color {
  color: inherit;
  width: 100%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-content-contacto-color {
  color: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-contacto-color {
  color: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-contacto-color {
  color: inherit;
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.rich-text-color {
  color: inherit;
  font-size: 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.texto-contacto-color {
  text-align: center;
  color: inherit;
  font-size: 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.text-color-strong {
  color: inherit;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-content-wrap-ubicacion {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-content-contacto-ubicacion {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-group-contacto-ubicacion {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-contacto-ubicacion {
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-content-contacto-ubicacion {
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  border-style: solid;
  border-width: 0;
  background-clip: border-box;
  border-color: #d6d6d6;
  border-top-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.module-contacto-ubicacion {
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module-content-contacto-ubicacion {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.infobox-wrap {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.infobox {
  justify-content: center;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-style: none;
  border-width: 0;
  background-clip: border-box;
  border-color: #000000;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  border-width: 0;
  padding: 20px;
  text-align: center;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.icon-wrapper {
  -webkit-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -o-animation-duration: 500ms;
  -ms-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.infobox-icon {
  border-width: 0;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.infobox-icon-inner {
  display: table;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.icon-span {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 25px;
  color: #00378a;
  display: table-cell;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.infobox-title-prefix {
  display: none;
}

.infobox-title-wrapper {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.infobox-title {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 20px;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.infobox-description {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.description-wrap {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.p1 {
  margin-bottom: 0 !important;
  line-height: 1.75;
}

.row-full-width {
  max-width: 1100px;
}

.row-content-wrap-separador {
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-content-separador {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-group-separador {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-separador {
  width: 100%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-content-separador {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-separador {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-separador {
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.content-separador {
  line-height: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.separador-contacto {
  border-top: 1px solid #cccccc;
  width: 50%;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-content-wrap-form {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  padding: 20px;
  margin: 0px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.row-content-form {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.col-group-contacto-form {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-contacto-form {
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-content-form {
  padding-right: 90px;
  padding-left: 90px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.module-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-header {
  margin-top: 70px;
  margin-bottom: 40px;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-contant-header {
  text-align: center;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-title-header {
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 36px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.title-text-span-header {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  display: inline-block;
  color: #3c4154;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module-contacto-form {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-form {
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.form-contacto {
  border-style: none;
  border-width: 0;
  background-clip: border-box;
  border-color: #ffffff;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin-top: 0em;
}

.col-content-mapa {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  width: 100%;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module-mapa {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-mapa {
  margin-top: 70px;
  margin-bottom: 40px;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-content-mapa {
  text-align: center;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-mapa {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.heading-title-mapa {
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 36px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 0 0 20px;
}

.module-iframe {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.module-content-iframe {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.map-iframe {
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.container-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contacto-button {
  width: 100px !important;
  height: 40px;
}

.iframe-macon {
  border: 0;
  width: 100%;
  pointer-events: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  overflow-clip-margin: content-box !important;
  border-width: 2px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  overflow: clip !important;
}

.module-iframe::before,
.module-content-mapa::before,
.module-mapa::before,
.module-contacto-form::before,
.module-content-header::before,
.module-header::before,
.row-content-form::before,
.module-content-separador::before,
.module-separador::before,
.col-separador::before,
.col-group-separador::before,
.row-content-separador::before,
.infobox::before,
.module-content-contacto-ubicacion::before,
.module-contacto-ubicacion::before,
.row-content-contacto-ubicacion::before,
.module-content-contacto-color::before,
.module-contacto-color::before,
.col-contacto-color::before,
.col-group-contacto-color::before,
.row-content-contacto-color::before,
.row-contacto::before {
  display: table;
  content: ' ';
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module-iframe::after,
.module-content-mapa::after,
.module-mapa::after,
.module-contacto-form::after,
.module-content-header::after,
.module-header::after,
.row-content-form::after,
.module-content-separador::after,
.module-separador::after,
.col-separador::after,
.col-group-separador::after,
.row-content-separador::after,
.infobox::after,
.module-content-contacto-ubicacion::after,
.module-contacto-ubicacion::after,
.row-content-contacto-ubicacion::after,
.module-content-contacto-color::after,
.module-contacto-color::after,
.col-contacto-color::after,
.col-group-contacto-color::after,
.row-content-contacto-color::after,
.row-contacto::after {
  clear: both;
  display: table;
  content: ' ';
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


.text-area {
  resize: none;
}

@media (max-width: 768px) {
  .row-content-wrap-form {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    background-attachment: scroll !important;
  }

  .col-group-contacto-form {
    display: block;
  }

  .col-contacto-form {
    max-width: 400px;
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }

  .col-content-form {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .module-content-header {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .col-content-mapa {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .module-content-mapa {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .map-iframe {
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
  }

  .iframe-macon {
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }

  .row-content-wrap-ubicacion {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .col-group-contacto-ubicacion {
    display: block;
  }

  .col-contacto-ubicacion{
    max-width: 400px;
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
  }

  .col-content-contacto-ubicacion{
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .heading-content-mapa {
    text-align: center;
  }

  .heading-contant-header {
    text-align: center;
  }

  .infobox{
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .heading-content-mapa {
    text-align: center;
  }

  .heading-contant-header {
    text-align: center;
  }

  .infobox{
    text-align: center;
  }
}